import { z } from 'zod'

export type Siret = string & { __siret: void }

const siretRegex = /^[0-9]{14}$/

export function parseSiret(input: unknown): Siret | null {
  if (typeof input !== 'string') {
    return null
  }
  const trimmedInput = input.replace(/\s/g, '').trim()
  if (siretRegex.test(trimmedInput)) {
    return trimmedInput as Siret
  }
  return null
}

export const siretStringZodSchema = z.string().refine((s) => parseSiret(s) !== null, {
  message: 'Invalid siret',
})

export class InvalidSiretError extends Error {
  constructor() {
    super('Input is not a valid siret')
  }
}

export function loadSiret(input: unknown): Siret {
  const siret = parseSiret(input)
  if (siret === null) {
    throw new InvalidSiretError()
  }
  return siret
}

export type JsonableSiretData = { type: 'provided'; siret: string } | { type: 'later' }
export const siretDataZodSchema = z.union([
  z.object({ type: z.literal('provided'), siret: siretStringZodSchema }),
  z.object({ type: z.literal('later') }),
])

export function extractSiretFromData(data?: JsonableSiretData | string | null): Siret | undefined {
  if (typeof data === 'string') return parseSiret(data) ?? undefined
  return !data || data.type === 'later' ? undefined : (parseSiret(data.siret) ?? undefined)
}
